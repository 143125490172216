import { useState } from "react";
import Shoe from "./Fiber3D/Shoe";
import Canvas from "./Fiber3D/Canvas";
import Chat from "./components/Chat";
import { Threekit as ThreekitLogo } from "./icons";
import { Wrapper, ChatWrapper, ThreekitLogoWrapper } from "./app.styles";
import { prepConfiguration } from "./utils";


export default function App() {
  const [configuration, setConfiguration] = useState();


  return (
    <Wrapper>
      <Canvas>
        <Shoe
          rotation={[0, 0, 0]}
          position={[0, 0, 0]}
          configuration={configuration}
        />
      </Canvas>
      <ChatWrapper>
        <Chat
          setConfiguration={setConfiguration}
          prepConfiguration={prepConfiguration}
        />
      </ChatWrapper>
      <ThreekitLogoWrapper href="https://threekit.com/">
        <center>
          <ThreekitLogo />
        </center>
        Powered by Threekit
        <br />
      </ThreekitLogoWrapper>
    </Wrapper>
  );
}
