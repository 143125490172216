import { useState, useRef, useEffect } from "react";
import {
  Wrapper,
  Title,
  Line,
  Content,
  ChatArea,
  AvatarWrapper,
  SentMessageText,
  ResponseMessageText,
  MessageArea,
  NewMesageArea,
  SubmitButton,
  SentMessageWrapper,
  ResponseMessageWrapper,
  WidgetButton,
  ConfigurationButton,
} from "./chat.styles";
import { Threekit as ThreekitLogo, Menu as MenuIcon, Open, Send } from "../../icons";
import LoadingDots from "../LoadingDots";
import * as api from "../../api";
import { generateUUID } from "../../utils";

const PRELOADED_MESSAGES = [
  {
    type: "response",
    text: "What kind of color scheme would you like to get started? If you're not sure, you can start by asking for a shoe in your favourite colors, or with the theme of your sports teams or even shoes to match your current fit.",
    configuration: null,
  },
];

const Avatar = (props) => {
  return (
    <AvatarWrapper color={props.color}>
      <div>{props.children}</div>
    </AvatarWrapper>
  );
};

const ApplyConfiguration = (props) => {
  if (!props.configuration) return null;
  return (
    <ConfigurationButton onClick={props.onClick}>
      <Open height="16px" color="white" />
    </ConfigurationButton>
  );
};

const SentMessage = (props) => {
  const { isMessageChain, text } = props;
  return (
    <SentMessageWrapper isMessageChain={isMessageChain}>
      <div />
      <SentMessageText>{text}</SentMessageText>
      {isMessageChain ? <div /> : <Avatar>me</Avatar>}
    </SentMessageWrapper>
  );
};
const ResponseMessage = (props) => {
  const { isMessageChain, text, loading, configuration, setConfiguration } =
    props;

  const handleClickApplyConfiguration = () => setConfiguration(configuration);

  return (
    <ResponseMessageWrapper isMessageChain={isMessageChain}>
      {isMessageChain ? (
        <div />
      ) : (
        <Avatar>
          <div style={{ paddingTop: "3px" }}>
            <ThreekitLogo width="14px" color="white" />
          </div>
        </Avatar>
      )}
      <ResponseMessageText>
        {loading ? <LoadingDots /> : text}
      </ResponseMessageText>
      <ApplyConfiguration
        configuration={configuration}
        onClick={handleClickApplyConfiguration}
      />
    </ResponseMessageWrapper>
  );
};

const Chat = (props) => {
  const { setConfiguration, prepConfiguration } = props;
  const [messages, setMessages] = useState(PRELOADED_MESSAGES);
  const [newMessage, setNewMessage] = useState("");
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [awaitingResponse, setAwaitingResponse] = useState(false);
  const textareaRef = useRef();
  const chatAreaRef = useRef();
  const userId = useRef(generateUUID());

  useEffect(() => {
    chatAreaRef.current.scrollTo(0, chatAreaRef.current.scrollHeight);
  }, [messages]);

  const handleSubmit = async () => {
    if (!newMessage.trim().length) return;
    if (awaitingResponse) return;
    setAwaitingResponse(true);
    setMessages([
      ...messages,
      {
        type: "sent",
        text: newMessage,
        configuration: null,
      },
    ]);
    setTimeout(() => {
      setMessages((currentVal) => {
        return [
          ...currentVal,
          {
            type: "response",
            text: "",
            configuration: null,
            loading: true,
          },
        ];
      });
    }, 0.3 * 1000);
    setNewMessage("");
    const { text, colors } = await api.postMessage(userId.current, newMessage);
    const configuration = prepConfiguration(colors);
    setMessages((val) => {
      const current = [...val];
      current.pop();
      return [
        ...current,
        {
          type: "response",
          text,
          configuration,
        },
      ];
    });
    setAwaitingResponse(false);
    if (configuration) setConfiguration(configuration);
  };

  const handleKeyUp = (e) => {
    textareaRef.current.style.height = `${e.target.scrollHeight}px`;
  };

  const handleChange = (e) => {
    if (awaitingResponse) return;
    if (e.keyCode === 13) return;
    setNewMessage(e.target.value);
  };

  const handleKeyDown = (e) => {
    if (e.key !== "Enter") return;
    handleSubmit();
    e.stopPropagation();
  };

  return (
    <Wrapper collapsed={isCollapsed}>
      <WidgetButton onClick={() => setIsCollapsed(!isCollapsed)}>
        <MenuIcon />
      </WidgetButton>
      <Content>
        <Title>THREEKIT STYLIST</Title>
        <Line />
        <ChatArea ref={chatAreaRef}>
          <div>
          {messages.map((message, i) => {
            const isMessageChain =
              i > 0 && messages[i - 1].type === message.type;
            if (message.type === "sent")
              return (
                <SentMessage {...message} isMessageChain={isMessageChain} />
              );
            return (
              <ResponseMessage
                {...message}
                isMessageChain={isMessageChain}
                setConfiguration={setConfiguration}
              />
            );
          })}
          </div>
        </ChatArea>
        <NewMesageArea>
          <MessageArea
            ref={textareaRef}
            value={newMessage}
            onKeyUp={handleKeyUp}
            onKeyDown={handleKeyDown}
            onChange={handleChange}
            placeholder="Type a message"
          />
          <SubmitButton disabled={awaitingResponse} onClick={handleSubmit}>
            <Send height="18px" />
          </SubmitButton>
        </NewMesageArea>
      </Content>
    </Wrapper>
  );
};

export default Chat